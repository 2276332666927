.swiper {
  img {
    pointer-events: none;
  }

  ion-card {
    text-align: center;
  }
}

.loots-row {
  display: flex;
  justify-content: center;
}

.loot-item {
  img {
    max-width: 64px;
  }
}

.weapons,
.characters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .weapons-title,
  .characters-title {
    width: 100%;
    padding: 16px;
    font-size: 20px;
    color: var(--ion-color-step-850, #262626);
    text-align: center;
  }

  .weapon,
  .character {
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --padding-start: 0;
    --background-hover: none;
    position: relative;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 10px;
    word-break: break-word;
    hyphens: auto;
  }

  .weapon-icon,
  .character-avatar {
      display: block;
      border-radius: 50%;
      max-width: 80px;
  }

  .character-element {
    width: 16px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #222428;
    border-radius: 50%;
    display: block;
  }

  .character-icon-web-page {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--ion-color-dark);
    width: 16px;
  }

  .weapon-name {
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    overflow: hidden;
    transition-duration: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--ion-color-dark);
    font-size: 12px;
  }
}

.weapon:hover {
  .weapon-name {
    top: 0;
  }
}
