.days-popover {
  ion-item {
    cursor: pointer;
    text-align: center;
  }

  ion-item:hover {
    font-size: 1.1em;
  }
}

.row-day-selected {
  position: sticky;
  top: 0;
  z-index: 2;
}
